import type {
	SetupArgs as LoomSetupArgs,
	Environment as LoomEnvironment,
} from '@loomhq/record-sdk';

import type { SessionDataType } from '@confluence/session-data';

const PUBLIC_APP_IDS = {
	noBackend: 'a3f31403-5892-4b68-baf9-a8b1c1f9ef80',
	staging: '811e05d4-b0aa-4ec9-80e4-68cf5e924102',
	production: {
		development: 'dba4f6db-8419-44f6-ac25-32b6fc352747',
		userFacing: '8e345daf-482a-4abf-a612-cb6457110817',
	},
};

type GetLoomInstanceDataByEnvironmentArgs = {
	environment: SessionDataType['environment'];
	jws: string;
	siteId: string;
};
export const getLoomInstanceDataByEnvironment = ({
	environment,
	jws,
	siteId,
}: GetLoomInstanceDataByEnvironmentArgs): LoomSetupArgs => {
	const commonParams = {
		jws,
		siteId,
		config: {
			enableNewUi: true,
			productName: 'Confluence',
		},
	};

	if (environment === 'DEVELOPMENT') {
		return {
			...commonParams,
			mode: 'standard',
			publicAppId: PUBLIC_APP_IDS.noBackend,
			environment: 'production' as LoomEnvironment, // TODO CTIA-2116: importing the enum as a value causes a big jump in package size (250kb).
		};
	}

	if (environment === 'STAGING') {
		return {
			...commonParams,
			mode: 'first-party',
			publicAppId: PUBLIC_APP_IDS.staging,
			environment: 'staging' as LoomEnvironment, // TODO CTIA-2116: importing the enum as a value causes a big jump in package size (250kb).
		};
	}

	return {
		...commonParams,
		mode: 'first-party',
		publicAppId:
			process.env.NODE_ENV === 'production'
				? PUBLIC_APP_IDS.production.userFacing
				: PUBLIC_APP_IDS.production.development,
		environment: 'production' as LoomEnvironment, // TODO CTIA-2116: importing the enum as a value causes a big jump in package size (250kb).
	};
};
