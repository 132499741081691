import { createHook, createStore } from 'react-sweet-state';
import type { Action } from 'react-sweet-state';
import {
	type SDKUnsupportedReasons,
	type createInstance as CreateLoomInstanceType,
} from '@loomhq/record-sdk';
import type { ApolloError } from 'apollo-client';

import type { SessionDataType } from '@confluence/session-data';

import { INITIALIZE_LOOM_RECORDER_SDK_INTERACTION_METRIC } from './perf.config';
import { getAuthToken } from './loomAuthData';
import { getLoomInstanceDataByEnvironment } from './getLoomInstanceDataByEnvironment';
import {
	type NoRecordingAccessReason,
	getNoRecordingAccessReason,
} from './getNoRecordingAcccessReason';

type InitializedLoomSDK = Awaited<ReturnType<typeof CreateLoomInstanceType>>;

export type StateType = {
	isInitializing: boolean;
	initializedLoomSDK: InitializedLoomSDK | null;
	_hasLoomSDKInitializationStarted: boolean;
	hasRecordingAccess: boolean;
	noRecordingAccessReason: NoRecordingAccessReason | undefined;
	authTokenError: ApolloError | Error | null | undefined;
	isLoomSDKSupportedError: SDKUnsupportedReasons | undefined;
};

type InitSDKParams = {
	cloudId: string;
	environment: SessionDataType['environment'];
};
type InitLoomSDKType = ({ cloudId }: InitSDKParams) => Action<StateType>;
type ActionsType = {
	initLoomSDK: InitLoomSDKType;
};

const initialState: StateType = {
	isInitializing: true,
	initializedLoomSDK: null,
	_hasLoomSDKInitializationStarted: false,
	hasRecordingAccess: false,
	noRecordingAccessReason: undefined,
	authTokenError: undefined,
	isLoomSDKSupportedError: undefined,
};

const initSDK: InitLoomSDKType =
	({ cloudId, environment }) =>
	async ({ getState, setState }) => {
		if (getState().initializedLoomSDK || getState()._hasLoomSDKInitializationStarted) {
			// singleton. only want 1 loom SDK instance
			return;
		}
		setState({ _hasLoomSDKInitializationStarted: true });
		INITIALIZE_LOOM_RECORDER_SDK_INTERACTION_METRIC.start();

		const { isSupported } = await import(
			/* webpackChunkName: "loadable-loomhq-record-sdk-is-supported" */ '@loomhq/record-sdk/is-supported'
		);
		const { supported, error: isLoomSDKSupportedError } = await isSupported();
		if (!supported) {
			setState({ isLoomSDKSupportedError, isInitializing: false });
			INITIALIZE_LOOM_RECORDER_SDK_INTERACTION_METRIC.cancel();
			return;
		}

		const { recorderToken, tokenError } = await getAuthToken();
		if (!recorderToken || tokenError) {
			const noRecordingAccessReason = await getNoRecordingAccessReason(tokenError);
			setState({
				isInitializing: false,
				noRecordingAccessReason,
				authTokenError: tokenError,
			});
			INITIALIZE_LOOM_RECORDER_SDK_INTERACTION_METRIC.cancel();
			return;
		}
		setState({ hasRecordingAccess: true });

		const { createInstance } = await import(
			/* webpackChunkName: "loadable-loomhq-record-sdk" */ '@loomhq/record-sdk'
		);
		const initializedLoomSDK = await createInstance(
			getLoomInstanceDataByEnvironment({
				environment,
				jws: recorderToken,
				siteId: cloudId,
			}),
		);
		setState({ initializedLoomSDK, isInitializing: false });
		INITIALIZE_LOOM_RECORDER_SDK_INTERACTION_METRIC.stop();
	};

const actions: ActionsType = {
	initLoomSDK: initSDK,
};

export const store = createStore<StateType, ActionsType>({
	initialState,
	actions,
	name: 'loomEntryPointGlobalStateStore',
});

export const useLoomEntryPointGlobalState = createHook(store);
