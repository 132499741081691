import {
	createInteractionMetric,
	OPEN_LOOM_RECORDER_SDK_INTERACTION,
	INITIALIZE_LOOM_RECORDER_SDK_INTERACTION,
} from '@confluence/browser-metrics';

export const OPEN_LOOM_RECORDER_SDK_INTERACTION_METRIC = createInteractionMetric({
	key: OPEN_LOOM_RECORDER_SDK_INTERACTION,
});

export const INITIALIZE_LOOM_RECORDER_SDK_INTERACTION_METRIC = createInteractionMetric({
	key: INITIALIZE_LOOM_RECORDER_SDK_INTERACTION,
});
