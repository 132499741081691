import { getATLContextDomain } from '@atlassian/atl-context';

import { useSessionData } from '@confluence/session-data';

const environmentMap = {
	DEVELOPMENT: 'dev',
	STAGING: 'stg',
	PRODUCTION: 'prod',
} as const;

export const useLoomCrossJoinUrl = () => {
	const { cloudId, environment } = useSessionData();
	// Using `environment` from session data (rather than letting `getATLContextDomain` determine it
	// internally) in order to better support local development that can be targeted at different
	// Confluence BE environments
	const identityDomain = getATLContextDomain('id', [environmentMap[environment], 'commercial']);
	const loomResourceAri = `ari:cloud:loom::site/${cloudId}`;
	const continueUrl =
		environment === 'PRODUCTION'
			? 'https://loom.com/api/auth/atlassian'
			: 'https://stage.loom.com/api/auth/atlassian';

	const userAccessUrl = encodeURIComponent(
		`https://${identityDomain}/join/user-access?resource=${loomResourceAri}&continue=${continueUrl}`,
	);

	return `https://${identityDomain}/login?continue=${userAccessUrl}`;
};
