import { fg } from '@confluence/feature-gating';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { isMobileUserAgent, shouldShowMobileWeb } from '@confluence/mobile-detection';

export const useIsLoomBetaEnabled = () => {
	const { accessStatus } = useSessionData();
	const isConfluenceLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;

	// don't enable entry points if user is on a mobile device or viewing mobile web
	const isMobileDeviceOrMobileWeb = isMobileUserAgent() || shouldShowMobileWeb();

	return isConfluenceLicensed && !isMobileDeviceOrMobileWeb && fg('confluence_loom_beta_enabled');
};
