import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook } from 'react-sweet-state';

import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { postJson } from './utils/fetch';
import { LicensedProductsQuery } from './graphql/LicensedProductsQuery.graphql';
import {
	LicenseStatus,
	type LicensedProductsQuery as LicensedProductsQueryType,
} from './graphql/__types__/LicensedProductsQuery';

enum LoomTenantProvisionStatus {
	PROVISIONED = 'provisioned',
	NOT_PROVISIONED = 'not-provisioned',
	ERROR = 'error,',
}

enum LoomUserPermissionStatus {
	PERMITTED = 'permitted',
	NOT_PERMITTED = 'not-permitted',
	ERROR = 'error,',
}

const getLoomTenantProvisionStatus = async (): Promise<LoomTenantProvisionStatus> => {
	try {
		const licensedProducts = await getApolloClient().query<LicensedProductsQueryType>({
			query: LicensedProductsQuery,
		});

		const isTenantLicensed = licensedProducts.data.tenantContext?.licensedProducts.some(
			(product) => product.productKey === 'loom' && product.licenseStatus === LicenseStatus.ACTIVE,
		);
		return isTenantLicensed
			? LoomTenantProvisionStatus.PROVISIONED
			: LoomTenantProvisionStatus.NOT_PROVISIONED;
	} catch (e) {
		getMonitoringClient().submitError(e, {
			attribution: Attribution.CONTENT_TYPES,
		});
		markErrorAsHandled(e);
		return LoomTenantProvisionStatus.ERROR;
	}
};

const getLoomUserPermissionStatus = async (cloudId: string): Promise<LoomUserPermissionStatus> => {
	try {
		const { permitted } = await postJson(`/gateway/api/permissions/permitted`, {
			permissionId: 'write',
			resourceId: `ari:cloud:loom::site/${cloudId}`,
			checkProductStatus: true,
		});
		return !!permitted
			? LoomUserPermissionStatus.PERMITTED
			: LoomUserPermissionStatus.NOT_PERMITTED;
	} catch (e) {
		getMonitoringClient().submitError(e, {
			attribution: Attribution.CONTENT_TYPES,
		});
		markErrorAsHandled(e);
		return LoomUserPermissionStatus.ERROR;
	}
};

export type LoomEntryPointVariantOption =
	| 'BETA'
	| 'CO_USE'
	| 'CROSS_FLOW'
	| 'CROSS_JOIN'
	| 'DISABLED'
	| 'NONE';

export type LoomEntryPointVariant = {
	entryPointVariant: LoomEntryPointVariantOption;
	error?: boolean;
	loading?: boolean;
};

export type LoomEntryPointVariantStateType = {
	loomEntryPointVariant: LoomEntryPointVariant;
};

type LoomEntryPointVariantActionType = {
	initLoomEntryPointVariant: (
		cloudId: string,
	) => ({ getState, setState }: StoreActionApi<LoomEntryPointVariantStateType>) => void;
};

const initialState = {
	loomEntryPointVariant: { entryPointVariant: 'NONE' as LoomEntryPointVariantOption },
};

export const loomEntryPointVariantActions: LoomEntryPointVariantActionType = {
	initLoomEntryPointVariant:
		(cloudId: string) =>
		async ({ getState, setState }) => {
			const state = getState();

			if (state.loomEntryPointVariant.entryPointVariant !== 'NONE') {
				return;
			}

			setState({
				loomEntryPointVariant: { entryPointVariant: 'CROSS_FLOW', loading: true },
			});

			const loomTenantProvisionStatus = await getLoomTenantProvisionStatus();

			if (loomTenantProvisionStatus === LoomTenantProvisionStatus.NOT_PROVISIONED) {
				setState({
					loomEntryPointVariant: { entryPointVariant: 'CROSS_FLOW' },
				});
				return;
			}

			if (loomTenantProvisionStatus === LoomTenantProvisionStatus.ERROR) {
				setState({
					loomEntryPointVariant: { entryPointVariant: 'CROSS_FLOW', error: true },
				});
				return;
			}

			setState({
				loomEntryPointVariant: { entryPointVariant: 'CROSS_JOIN', loading: true },
			});

			const loomUserPermissionStatus = await getLoomUserPermissionStatus(cloudId);

			if (loomUserPermissionStatus === LoomUserPermissionStatus.NOT_PERMITTED) {
				setState({
					loomEntryPointVariant: { entryPointVariant: 'CROSS_JOIN' },
				});
				return;
			}

			if (loomUserPermissionStatus === LoomUserPermissionStatus.ERROR) {
				setState({
					loomEntryPointVariant: { entryPointVariant: 'CROSS_JOIN', error: true },
				});
				return;
			}
			setState({
				loomEntryPointVariant: { entryPointVariant: 'CO_USE' },
			});
		},
};

const loomEntryPointVariantStore = createStore<
	LoomEntryPointVariantStateType,
	LoomEntryPointVariantActionType
>({
	initialState,
	actions: loomEntryPointVariantActions,
	name: 'loomEntryPointVariantState',
});

export const useLoomEntryPointVariantState = createHook(loomEntryPointVariantStore);
