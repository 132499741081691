/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum LoomUserStatus {
  LINKED = "LINKED",
  LINKED_ENTERPRISE = "LINKED_ENTERPRISE",
  MASTERED = "MASTERED",
  NOT_FOUND = "NOT_FOUND",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoomAuthStateQuery
// ====================================================

export interface LoomAuthStateQuery {
  loomUserStatus: LoomUserStatus;
}
