/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum LicenseStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  UNLICENSED = "UNLICENSED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LicensedProductsQuery
// ====================================================

export interface LicensedProductsQuery_tenantContext_licensedProducts {
  licenseStatus: LicenseStatus;
  productKey: string;
}

export interface LicensedProductsQuery_tenantContext {
  licensedProducts: LicensedProductsQuery_tenantContext_licensedProducts[];
}

export interface LicensedProductsQuery {
  tenantContext: LicensedProductsQuery_tenantContext | null;
}
