import gql from 'graphql-tag';

export const LicensedProductsQuery = gql`
	query LicensedProductsQuery {
		tenantContext {
			licensedProducts {
				licenseStatus
				productKey
			}
		}
	}
`;
